import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { IconMap } from '../../../../../src/shared/sprite';
import { Icon } from '../../../../../src/shared/ui';

export type TimelineNodeType = NodeViewProps['node'];

export type TimelineNodeViewProps = NodeViewProps & {
  node: TimelineNodeType;
};

export const TimelineNodeView = ({
  node,
  getPos,
  editor,
}: TimelineNodeViewProps) => {
  const pos = getPos();

  return (
    <NodeViewWrapper className="!border-none">
      <div data-type="timeline" className="relative">
        <div className="absolute top-0 left-10 w-px bg-gray-300 z-10 mt-4 h-[calc(100%-40px)]" />
        <NodeViewContent />
        <button
          contentEditable={false}
          className="items-center flex gap-6 px-3 pt-6"
          onClick={() => {
            editor
              .chain()
              .focus()
              .insertContentAt(pos + node.nodeSize - 1, {
                type: 'timelineItem',
                content: [
                  {
                    type: 'heading',
                    attrs: { level: 4, textAlign: 'left' },
                  },
                  {
                    type: 'paragraph',
                  },
                ],
              })

              .focus(pos + node.nodeSize)
              .run();
          }}
        >
          <div className="z-20 flex items-center justify-center border rounded-full border-base-black/10 h-14 w-14 min-w-14 bg-base-white">
            <Icon glyph={IconMap.Plus} width={24} className="text-gray-600" />
          </div>
          <span className="font-semibold text-gray-700 text-md">
            Add new phase
          </span>
        </button>
      </div>
    </NodeViewWrapper>
  );
};
