import { FC, PropsWithChildren, useReducer } from 'react';
import { INITIAL_STATE, reducer } from './state';
import { PlayerContext } from './PlayerContext';

import { PlayerEntry, PlayerSettings } from '../../types';

export const PlayerProvider: FC<
  PropsWithChildren<PlayerEntry & PlayerSettings>
> = ({ children, ...props }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <PlayerContext.Provider value={{ state, dispatch, config: props }}>
      {children}
    </PlayerContext.Provider>
  );
};
