import React, { ChangeEvent, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { authUserModel } from '../../../entities/auth-user';
import { teamsModel } from '../../../features/teams';
import { pagesOverviewModel } from '../../../features/pages-overview';
import {
  Avatar,
  Dropdown,
  Icon,
  Input,
  LoaderDots,
  SkeletonItem,
} from '../../../shared/ui';
import { Table } from './components/Table';
import { IconMap } from '../../../shared/sprite';
import { debounce } from 'lodash';

const DEFAULT_OWNER_ID = 'all';

const blocks = [
  { id: 'totalPages', name: 'Total pages created' },
  { id: 'totalPageViews', name: 'Total page views' },
  { id: 'totalLeads', name: 'Total leads' },
];

const sortItems = [
  { label: 'Last viewed', id: 'leastViewed' },
  { label: 'Last created', id: 'lastCreated' },
  { label: 'Name', id: 'name' },
];

export const PagesOverview: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(authUserModel.selectors.selectUser);
  const currentTeamMembers = useSelector(
    teamsModel.selectors.selectCurrentTeamMembers
  );

  const total: { [key: string]: number } = useSelector(
    pagesOverviewModel.selectors.selectTotal
  );

  const isDataLoading = useSelector(
    pagesOverviewModel.selectors.selectIsOverviewDataLoading
  );
  const isTotalLoading = useSelector(
    pagesOverviewModel.selectors.selectIsTotalLoading
  );
  const data = useSelector(
    pagesOverviewModel.selectors.selectPagesOverviewData
  );
  const meta = useSelector(
    pagesOverviewModel.selectors.selectPagesOverviewMeta
  );
  const search = useSelector(pagesOverviewModel.selectors.selectSearch);
  const orderBy = useSelector(pagesOverviewModel.selectors.selectOrderBy);
  const selectedFilterByUserId = useSelector(
    pagesOverviewModel.selectors.selectFilterByUserId
  );

  const onOwnerChange = (ownerId: string) => {
    const userId = ownerId === DEFAULT_OWNER_ID ? undefined : ownerId;
    dispatch(pagesOverviewModel.actions.setFilterByUserId(userId));
    dispatch(pagesOverviewModel.actions.getPagesOverview({}));
  };

  const onSortChange = (sortId: string) => {
    dispatch(pagesOverviewModel.actions.setOrderBy(sortId));
    dispatch(pagesOverviewModel.actions.getPagesOverview({}));
  };

  const owners = useMemo(
    () => [
      {
        label: 'All owners',
        id: DEFAULT_OWNER_ID,
      },
      ...currentTeamMembers
        .filter((member) => member.user)
        .map((member) => ({
          label: (
            <div className="flex flex-row gap-x-3 w-full">
              <Avatar
                displayName={member.user?.displayName ?? ''}
                src={member.user?.photoUrl}
                isUserAvatar
                size="xs"
              />
              <p className="font-medium text-s text-gray-700 truncate">
                {member.user?.displayName}
              </p>
              <p className="text-gray-600 text-s truncate">
                {member.user?.email}
              </p>
            </div>
          ),
          headerLabel: member.user?.displayName,
          id: member.user?.id as string,
        })),
    ],
    [currentTeamMembers]
  );

  const handleGetPagesOverview = useCallback(
    debounce(() => {
      dispatch(pagesOverviewModel.actions.getPagesOverview({}));
    }, 400),
    [dispatch]
  );

  const handlePageChange = (page: number) => {
    dispatch(pagesOverviewModel.actions.getPagesOverview({ page }));
  };

  const handlePageSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchVal = e.target.value;
    dispatch(pagesOverviewModel.actions.setSearching(searchVal));
    handleGetPagesOverview();
  };

  return (
    <>
      <Helmet titleTemplate="Overview - Distribute" />
      <main className="w-full">
        <div className="flex justify-between gap-4 items-end pb-8 sm:flex-wrap">
          <header>
            <h1 className="font-medium text-gray-900 text-display-sm font-display">
              Welcome back, {user?.displayName} 👋
            </h1>
            <p className="text-gray-600">
              Your current team summary and activity.
            </p>
          </header>

          <div className="w-80">
            <Dropdown
              listStyles="shadow-lg w-80"
              items={owners}
              currentItemId={selectedFilterByUserId ?? DEFAULT_OWNER_ID}
              itemStyles="w-full"
              triggerStyles="w-40 text-sm !text-gray-700"
              onItemChange={onOwnerChange}
            />
          </div>
        </div>

        <section className="pb-8">
          <div className="grid grid-cols-3 gap-4 sm:grid-cols-1">
            {blocks.map((block) => (
              <div
                key={block.id}
                className="border border-gray-200 rounded-lg p-6"
              >
                <p className="pb-1.5 leading-5">{block.name}</p>
                {isTotalLoading ? (
                  <SkeletonItem className="rounded-lg" height="40px" />
                ) : (
                  <p className="font-medium text-gray-900 truncate text-display-sm font-display">
                    {total[block.id] ?? '-'}
                  </p>
                )}
              </div>
            ))}
          </div>
        </section>
        <section>
          <div className="flex justify-between items-center pb-5 sm:flex-wrap">
            <h2 className="font-display font-medium text-gray-900 text-display-xs pr-2">
              Pages
            </h2>
            <div className="flex gap-2 sm:flex-wrap">
              <div className="sm:max-w-full w-80 relative">
                <Icon
                  glyph={IconMap.SearchLg}
                  width={20}
                  className="absolute left-3.5 top-2.5 z-10 text-gray-500"
                />
                <Input
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={handlePageSearch}
                  heightSize="md"
                  className="!pl-10.5 w-80"
                />
              </div>
              <Dropdown
                listStyles="shadow-lg"
                items={sortItems}
                currentItemId={orderBy}
                itemStyles="w-44 max-w-48"
                triggerStyles="w-48 text-sm !text-gray-700 max-w-48"
                onItemChange={onSortChange}
              />
            </div>
          </div>

          {isDataLoading ? (
            <LoaderDots className="mt-44" />
          ) : (
            <Table
              data={data}
              currentPage={meta.currentPage}
              totalPages={meta.totalPages}
              handleChangePage={handlePageChange}
            />
          )}
        </section>
      </main>
    </>
  );
};

export default PagesOverview;
