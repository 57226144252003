import { FC, useRef } from 'react';
import ReactPlayer from 'react-player';

import cn from 'classnames';

import { usePlayer, usePlayerActions, useRetry } from '../../hooks';

import { PlayerBubbleOverlay } from './PlayerBubbleOverlay';
import { PlayerBubblePreview } from './PlayerBubblePreview';
import { PlayerBubbleTooltip } from './PlayerBubbleTooltip';

import { PlayerBubbleControls } from './PlayerBubbleControls';

export const PlayerBubble: FC = () => {
  const playerRef = useRef<ReactPlayer>(null);

  const { state, config } = usePlayer();

  const {
    handlePlay,
    handlePause,
    handleEnded,
    handleDuration,
    handleProgress,
  } = usePlayerActions();

  const { handleError } = useRetry(playerRef);

  const { url, aspectRatio } = config;
  const { playing, playbackRate, volume, start } = state;

  return (
    <div
      className={cn(
        'relative cursor-auto outline-none transition-all duration-150 group',

        {
          'sm:w-36 sm:h-36 sm:hover:w-40 sm:hover:h-40 w-56 h-56 hover:w-60 hover:h-60':
            !start,
          'w-80 h-80': start,
        }
      )}
      style={{ aspectRatio: config.aspectRatio }}
    >
      <div className="absolute inset-0 overflow-hidden rounded-xl">
        <ReactPlayer
          className="relative bg-base-black scale-x-[-1]"
          ref={playerRef}
          url={url}
          width="100%"
          height="100%"
          style={{ visibility: start ? 'visible' : 'hidden' }}
          playbackRate={playbackRate}
          playsinline
          playing={playing}
          volume={volume}
          onDuration={handleDuration}
          onProgress={handleProgress}
          onError={handleError}
          onPause={handlePause}
          onPlay={handlePlay}
          onEnded={() => handleEnded(playerRef)}
          config={{
            file: {
              attributes: {
                style: { aspectRatio },
                className: 'w-full bg-base-black',
                controlsList: 'nodownload',
              },
            },
          }}
        />
        <PlayerBubbleOverlay />
        <PlayerBubbleControls playerRef={playerRef} />
        <PlayerBubblePreview />
      </div>
      <PlayerBubbleTooltip />
    </div>
  );
};
