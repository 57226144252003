import { FC } from 'react';

import { PlayerButton } from './PlayerButton';
import { PlayerTooltip } from './PlayerTooltip';
import { PlayerKey } from './PlayerKey';

import { usePlayer, usePlayerActions } from '../../../hooks';

export const PlayerPlaybackRate: FC = () => {
  const { state, config } = usePlayer();
  const { handleSetPlaybackRate } = usePlayerActions();

  const { playbackRate, fullscreen } = state;
  const { hotKeys } = config;

  const sizeControl = fullscreen ? 'md' : 'sm';

  return (
    <PlayerTooltip
      isOpen={fullscreen || hotKeys ? undefined : false}
      size={sizeControl}
      trigger={
        <PlayerButton
          size={sizeControl}
          variant="transparent"
          onClick={handleSetPlaybackRate}
        >
          {`${playbackRate}x`}
        </PlayerButton>
      }
    >
      <span>Playback speed</span>
      <PlayerKey size={sizeControl}>S</PlayerKey>
    </PlayerTooltip>
  );
};
