import { FC, useEffect } from 'react';
import '../../../../shared/styles/generate-html.css';
import {
  addBreaks,
  addTimelineDueDate,
  handleHtmlContentElementClick,
  highlightCodeBlock,
  setCorrectColumnWidth,
} from '@distribute/shared/generate-html';

type Props = {
  htmlContent: string;
};

export const EditorHtmlContent: FC<Props> = ({ htmlContent }) => {
  useEffect(() => {
    window.addEventListener('click', handleHtmlContentElementClick);

    highlightCodeBlock();
    setCorrectColumnWidth();
    addBreaks();
    addTimelineDueDate();

    return () => {
      window.removeEventListener('click', handleHtmlContentElementClick);
    };
  }, []);

  return (
    <div
      className="rich-text-content"
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    ></div>
  );
};
