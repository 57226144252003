import ReactPlayer from 'react-player';

import { RefObject } from 'react';
import { ActionKind } from '../provider/player';
import { usePlayer } from './use-player';

import { checkIsInf } from '../lib';

export const usePlayerActions = () => {
  const { dispatch } = usePlayer();

  const handleStart = () => dispatch({ type: ActionKind.START });

  const handlePlay = () => dispatch({ type: ActionKind.PLAY });

  const handlePause = () => dispatch({ type: ActionKind.PAUSE });

  const handleToggle = () => dispatch({ type: ActionKind.TOGGLE_PLAY });

  const handleToggleVolume = () => dispatch({ type: ActionKind.TOGGLE_VOLUME });

  const handleSetVolume = ([value]: [number]) =>
    dispatch({ type: ActionKind.VOLUME, payload: value });

  const handleSetPlaybackRate = () =>
    dispatch({ type: ActionKind.PLAYBACK_RATE });

  const handleSetFullscreen = (open: boolean) =>
    dispatch({
      type: ActionKind.SET_FULLSCREEN,
      payload: open,
    });

  const handleProgress = (progress: {
    playedSeconds: number;
    loaded: number;
  }) => {
    dispatch({ type: ActionKind.SEEK, payload: progress.playedSeconds });
    dispatch({ type: ActionKind.SEEK_LOADED, payload: progress.loaded });
  };

  const handleSetSeek = (ref: RefObject<ReactPlayer>, value: number) => {
    const duration = ref.current?.getDuration() || 0;

    if (checkIsInf(duration) || duration <= 0) return;

    const diff = Math.abs(duration - value);
    const newValue = diff < 0.1 ? duration : value;

    ref.current?.seekTo(newValue, 'seconds');
    dispatch({ type: ActionKind.SEEK, payload: newValue });
    dispatch({ type: ActionKind.FINISH, payload: newValue === duration });
  };

  const handleDuration = (duration: number) => {
    dispatch({ type: ActionKind.DURATION, payload: duration });
  };

  const handleEnded = (ref: RefObject<ReactPlayer>) => {
    const payload = ref.current?.getDuration() || 0;
    dispatch({ type: ActionKind.ENDED, payload });
  };

  const handleReset = (ref: RefObject<ReactPlayer>) => {
    ref.current?.seekTo(0);
    dispatch({ type: ActionKind.RESET });
  };

  const handleRetry = (ref: RefObject<ReactPlayer>) => {
    ref.current?.seekTo(0);
    dispatch({ type: ActionKind.START });
  };

  return {
    handleStart,
    handlePlay,
    handlePause,
    handleToggle,
    handleToggleVolume,
    handleSetVolume,
    handleSetPlaybackRate,
    handleSetFullscreen,
    handleProgress,
    handleSetSeek,
    handleDuration,
    handleEnded,
    handleReset,
    handleRetry,
  };
};
