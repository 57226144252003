import cn from 'classnames';
import { checkIsInf, toPrettySeconds } from '../../../lib';
import { usePlayer } from '../../../hooks';

export const PlayerDuration = () => {
  const { state } = usePlayer();

  const { playedSeconds, duration, fullscreen } = state;

  const isInf = checkIsInf(duration);

  return (
    <div
      className={cn(
        'select-none absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-base-white font-base text-sm font-semibold flex gap-x-2 items-center whitespace-nowrap',
        {
          hidden: !duration,
          'text-sm': !fullscreen,
          'text-xl': fullscreen,
        }
      )}
    >
      {isInf ? (
        <span>{toPrettySeconds(playedSeconds)}</span>
      ) : (
        <>
          <span>{toPrettySeconds(playedSeconds)}</span>
          <span>/</span>
          <span>{toPrettySeconds(duration)}</span>
        </>
      )}
    </div>
  );
};
