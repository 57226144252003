import { FC, RefObject } from 'react';
import { usePlayer, usePlayerActions } from '../../hooks';

import ReactPlayer from 'react-player';

import cn from 'classnames';

import { Icon, Icons } from '../../icons';

import { PlayerButton } from '../Player/PlayerControls/PlayerButton';
import { PlayerPlaybackRate } from '../Player/PlayerControls/PlayerPlaybackRate';
import { PlayerProgress } from '../Player/PlayerControls/PlayerProgress';
import { PlayerDuration } from '../Player/PlayerControls/PlayerDuration';
import { PlayerVolume } from '../Player/PlayerControls/PlayerVolume';

type Props = {
  playerRef: RefObject<ReactPlayer>;
};

export const PlayerBubbleControls: FC<Props> = ({ playerRef }) => {
  const { state } = usePlayer();
  const { handleReset, handleToggle, handleRetry } = usePlayerActions();

  const { start, playing, finish } = state;

  if (start) {
    return (
      <>
        <div className="absotule top-2 right-2">
          <PlayerButton
            onClick={() => handleReset(playerRef)}
            variant="transparent"
            size="sm"
            className="absolute top-2 right-2"
          >
            <Icon svg={Icons.Cross} size="sm" />
          </PlayerButton>
        </div>
        <div
          className={cn(
            'absolute bottom-0 flex flex-col w-full bg-gradient-to-t from-base-black to-base-black/0',
            'h-16 px-4 pb-2.5'
          )}
        >
          <PlayerProgress playerRef={playerRef} />
          <div className="relative flex justify-between items-center mt-auto">
            <PlayerDuration />
            <div className="flex flex-nowrap gap-x-1">
              {finish ? (
                <PlayerButton
                  size="sm"
                  variant="transparent"
                  onClick={() => handleRetry(playerRef)}
                >
                  <Icon svg={Icons.Retry} size="sm" />
                </PlayerButton>
              ) : (
                <PlayerButton
                  size="sm"
                  variant="transparent"
                  onClick={handleToggle}
                >
                  <Icon svg={playing ? Icons.Pause : Icons.Play} size="sm" />
                </PlayerButton>
              )}
              <PlayerVolume />
            </div>
            <div className="flex flex-nowrap gap-x-1">
              <PlayerPlaybackRate />
            </div>
          </div>
        </div>
      </>
    );
  }

  return null;
};
