import { FC } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '../../../../shared/ui/select/SelectV2';

import { Button, Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';

import { narrationModel } from '../../model';
import { checkIsNarrationReady } from '@distribute/shared/utils';
import { editorSidebarModel } from '../../../editor-sidebar';
import { pagesModel } from '../../../pages';
import { subscribePopupModel } from '../../../subscribe-popup';
import { gatedContentModel } from '../../../gated-content-block';
import { squeezePageModel } from '../../../squeeze-page';

const popupId = 'narration-popup';

export const NarrationPopup: FC = () => {
  const dispatch = useDispatch();

  const { videos, videoId, audios, audioId } = useSelector(
    narrationModel.selectors.selectNarration
  );

  return (
    <div
      className="overflow-hidden w-70 p-4 flex flex-col gap-y-4 bg-base-white pointer-events-auto border border-base-black/[0.08] rounded-xl shadow-lg"
      id={popupId}
    >
      <div className="top-2 -translate-x-1/2 left-1/2 absolute flex items-center w-12 h-4 mx-auto cursor-move narration-draggable-item">
        <div className="w-full h-1 rounded-md bg-gray-200" />
      </div>

      <div className="mt-4 flex flex-nowrap gap-x-4 justify-between items-center">
        <p className="text-md font-semibold text-base-black">
          Record narration
        </p>
        <button
          onClick={() => dispatch(narrationModel.actions.setPopupOpen(false))}
          type="button"
          className="text-gray-600 hover:text-gray-700 flex-shrink-0"
        >
          <Icon glyph={IconMap.CrossClose} width={24} />
        </button>
      </div>
      <Select
        value={videoId}
        onValueChange={(value) =>
          dispatch(narrationModel.actions.setVideoId(value))
        }
      >
        <SelectTrigger className="!h-11 !text-md !font-normal !text-gray-900">
          <span className="flex flex-nowrap gap-x-2 items-center">
            <Icon
              glyph={IconMap.VideoRecorder}
              width={20}
              className="text-gray-500 flex-shrink-0"
            />
            <span className="truncate">
              {videos.find((video) => video.id === videoId)?.label}
            </span>
          </span>
        </SelectTrigger>
        <SelectContent
          side="bottom"
          align="end"
          sideOffset={4}
          containerId={popupId}
        >
          {videos.map((video) => (
            <SelectItem value={video.id} key={video.id}>
              {video.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Select
        value={audioId}
        onValueChange={(value) =>
          dispatch(narrationModel.actions.setAudioId(value))
        }
      >
        <SelectTrigger className="!h-11 !text-md !font-normal !text-gray-900">
          <span className="flex flex-nowrap gap-x-2 items-center">
            <Icon
              glyph={IconMap.Microphone}
              width={20}
              className="text-gray-500 flex-shrink-0"
            />
            <span className="truncate">
              {audios.find((audio) => audio.id === audioId)?.label}
            </span>
          </span>
        </SelectTrigger>
        <SelectContent
          side="bottom"
          align="end"
          sideOffset={4}
          containerId={popupId}
        >
          {audios.map((audio) => (
            <SelectItem value={audio.id} key={audio.id}>
              {audio.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <BubbleActions />
    </div>
  );
};

export const BubbleActions: FC = () => {
  const dispatch = useDispatch();

  const isNoVideo = useSelector(narrationModel.selectors.checkIsNoVideo);

  const isVideoPermissionInActive = useSelector(
    narrationModel.selectors.checkIsVideoPermissionInActive
  );

  const isNarrationRecordingSessionStopped = useSelector(
    narrationModel.selectors.checkIsNarrationRecordingSessionStopped
  );
  const isNarrationRecordingSessionActive = useSelector(
    narrationModel.selectors.checkIsNarrationRecordingSessionActive
  );
  const isNarrationMachineIdle = useSelector(
    narrationModel.selectors.checkIsNarrationMachineIdle
  );

  const narration = useSelector(narrationModel.selectors.selectNarrationData);

  const handleStart = () => {
    dispatch(subscribePopupModel.actions.setIsModalOpen(false));
    dispatch(gatedContentModel.actions.setIsModalOpen(false));
    dispatch(squeezePageModel.actions.setIsOpen(false));

    dispatch(editorSidebarModel.actions.setSidebarOpen(false));
    dispatch(pagesModel.actions.setIsEditorPreview(false));

    dispatch(narrationModel.actions.start());
  };

  const isNarrationReady = checkIsNarrationReady(narration);

  if (isNarrationRecordingSessionStopped) {
    return (
      <div className="flex flex-nowrap gap-x-3">
        <Button
          fullWidth
          type="button"
          variant="text"
          color="secondary"
          size="md"
          disabled
        >
          Processing...
        </Button>
      </div>
    );
  }

  if (narration) {
    return (
      <div className="flex flex-nowrap gap-x-3">
        <Button
          fullWidth
          type="button"
          variant="text"
          color="secondary"
          size="md"
          onClick={handleStart}
          disabled={!isNarrationReady || isNoVideo || isVideoPermissionInActive}
          loading={isNarrationRecordingSessionActive || !isNarrationMachineIdle}
        >
          {isNarrationReady ? (
            <>
              <Icon
                glyph={IconMap.RecordingStart}
                width={20}
                className="mr-2"
              />
              Retake
            </>
          ) : (
            <>Processing...</>
          )}
        </Button>
        <Button
          fullWidth
          type="button"
          variant="text"
          color="secondary"
          size="md"
          disabled={isNarrationRecordingSessionActive}
          onClick={() => dispatch(narrationModel.actions.deleteNarration())}
        >
          <Icon glyph={IconMap.Delete} width={20} className="mr-2" />
          Delete
        </Button>
      </div>
    );
  }

  return (
    <Button
      fullWidth
      type="button"
      variant="text"
      color="primary"
      size="md"
      disabled={isNoVideo || isVideoPermissionInActive}
      loading={isNarrationRecordingSessionActive || !isNarrationMachineIdle}
      onClick={handleStart}
    >
      <Icon glyph={IconMap.RecordingStart} width={20} className="mr-2" />
      Start Recording
    </Button>
  );
};
