import { FC, PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  checkIsNarrationError,
  checkIsNarrationReady,
  getMuxImage,
  getMuxStream,
} from '@distribute/shared/utils';

import { PlayerBubble } from '@distribute/frontend/ui/player';

import { narrationModel } from '../../model';

import { NarrationBubbleCamera } from './narration-bubble-camera';
import { NarrationBubbleLoader } from './narration-bubble-loader';

import { squeezePageModel } from '../../../squeeze-page';
import { subscribePopupModel } from '../../../subscribe-popup';
import { gatedContentModel } from '../../../gated-content-block';
import { editorSidebarModel } from '../../../editor-sidebar';
import { pagesModel } from '../../../pages';

export const NarrationBubbleWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="fixed inset-0 z-30 pointer-events-none">
      <div className="absolute left-8 bottom-8 pointer-events-auto">
        {children}
      </div>
    </div>
  );
};

export const NarrationBubble: FC = () => {
  const dispatch = useDispatch();

  const squeezeIsOpen = useSelector(squeezePageModel.selectors.selectIsOpen);

  const popupOpen = useSelector(narrationModel.selectors.selectPopupOpen);
  const narration = useSelector(narrationModel.selectors.selectNarrationData);

  const isNarrationRecordingSessionActive = useSelector(
    narrationModel.selectors.checkIsNarrationRecordingSessionActive
  );
  const isNarrationRecordingSessionStopped = useSelector(
    narrationModel.selectors.checkIsNarrationRecordingSessionStopped
  );

  const isPopupVisible = useSelector(
    narrationModel.selectors.checkIsPopupVisible
  );

  const handleOpenNarrationPopup = () => {
    dispatch(subscribePopupModel.actions.setIsModalOpen(false));
    dispatch(gatedContentModel.actions.setIsModalOpen(false));
    dispatch(squeezePageModel.actions.setIsOpen(false));

    dispatch(editorSidebarModel.actions.setSidebarOpen(false));
    dispatch(pagesModel.actions.setIsEditorPreview(false));

    dispatch(narrationModel.actions.setPopupOpen(true));
  };

  const isNarrationReady = checkIsNarrationReady(narration);
  const isNarrationError = checkIsNarrationError(narration);

  // avoid it and fix timeline z-index
  if (squeezeIsOpen) return null;

  if (isNarrationRecordingSessionActive) {
    return <NarrationBubbleCamera />;
  }

  if (isNarrationRecordingSessionStopped) {
    return <NarrationBubbleLoader />;
  }

  if (narration) {
    return (
      <PlayerBubble
        onEditClick={
          isPopupVisible ? undefined : () => handleOpenNarrationPopup()
        }
        loading={!isNarrationReady}
        error={isNarrationError}
        aspectRatio={narration.metadata?.mux?.aspectRatio || '1/1'}
        url={getMuxStream(narration.playbackId || '')}
        posterUrl={getMuxImage(narration.playbackId || '')}
      />
    );
  }

  if (popupOpen) {
    return <NarrationBubbleCamera />;
  }

  return null;
};
