export const OVERVIEW_COLUMNS = [
  {
    header: 'Page',
    name: 'page',
  },
  // {
  //   header: 'Trend',
  //   name: 'trend',
  // }, // TODO: temporary removed
  {
    header: 'Page views',
    name: 'pageViews',
  },
  {
    header: 'Unique visitors',
    name: 'uniqVisitors',
  },
  {
    header: 'Owner',
    name: 'owner',
  },
  {
    header: 'Shared with',
    name: 'sharedWith',
  },
  {
    header: 'Last viewed',
    name: 'lastViewed',
  },
] as const;
