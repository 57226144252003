import { FC, useRef } from 'react';

import { PlayerSettings } from '../../types';
import { PlayerProvider } from '../../provider/player';

import { PlayerErrorBoundary } from '../PlayerErrorBoundary';
import { PlayerBubbleLoader } from './PlayerBubbleLoader';
import { PlayerBubbleView } from './PlayerBubbleView';
import { PlayerBubbleProvider } from '../../provider/player-bubble';

type Props = {
  onEditClick?: () => void;
  interactive?: boolean;
};

export const PlayerBubble: FC<PlayerSettings & Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { interactive, onEditClick, ...playerProps } = props;

  return (
    <PlayerErrorBoundary fallback={<PlayerBubbleLoader />}>
      <PlayerBubbleProvider interactive={interactive} onEditClick={onEditClick}>
        <PlayerProvider {...playerProps} wrapperRef={ref}>
          <PlayerBubbleView />
        </PlayerProvider>
      </PlayerBubbleProvider>
    </PlayerErrorBoundary>
  );
};
