import { FC } from 'react';

import { usePlayer } from '../../hooks';

import { Player } from './Player';
import { PlayerLoader } from './PlayerLoader';
import { PlayerError } from './PlayerError';
import { PlayerPreview } from './PlayerPreview';

export const PlayerView: FC = () => {
  const { state, config } = usePlayer();

  if (config.loading) return <PlayerLoader />;

  if (config.error || state.error) return <PlayerError />;

  return state.start ? <Player /> : <PlayerPreview />;
};
