import { NarrationMessageAction } from './message-action';

import { VideoNarrationDetailType } from '@distribute/shared/api-types/video-narration';

export enum NarrationAction {
  DeleteNarration = 'narration/deleteNarrration',

  UpdateAudio = 'narration/updateAudio',
  UpdateVideo = 'narration/updateVideo',

  Start = 'narration/start',

  StartRecording = 'narration/startRecording',
  StartUploadingSession = 'narration/startUploadingSession',

  ActivateRecorder = 'narration/activateRecorder',
  DestroyRecorder = 'narration/destroyRecorder',

  ResumeRecording = 'narration/resumeRecording',
  PauseRecording = 'narration/pauseRecording',
  StopRecording = 'narration/stopRecording',
  CancelRecording = 'narration/cancelRecording',
  RestartRecording = 'narration/restartRecording',

  CancelUploadSession = 'narration/cancelUploadSession',
  CompleteUploadSession = 'narration/completeUploadSession',
}

export enum DevicePermission {
  PROMPT = 'prompt',
  GRANTED = 'granted',
  DENIED = 'denied',
}

export type DeviceSource = {
  id: string;
  label: string;
};

export enum NarrationMediaState {
  Idle = 'idle',
  Active = 'active',
  Error = 'error',
}

export enum NarrationLogicState {
  Idle = 'idle',
  Initialization = 'initialization',
  LoadSession = 'load-session',
  Running = 'running',
  Paused = 'paused',
  Resuming = 'resuming',
  Stopped = 'stopped',
  Completed = 'completed',
}

export enum NarrationCountdownState {
  Idle = 'idle',
  Active = 'active',
  Completed = 'completed',
}

export type NarrationTime = {
  start: number;
  pause: number;
  pauseAll: number;
  duration: number;
};

export type NarrationState = {
  narration: VideoNarrationDetailType | null;
  narrationActive: VideoNarrationDetailType | null;

  popupOpen: boolean;

  videoPermission: DevicePermission;
  videos: DeviceSource[];
  videoId: string;

  audioPermission: DevicePermission;
  audios: DeviceSource[];
  audioId: string;

  time: NarrationTime;
  mediaState: NarrationMediaState;
  logicState: NarrationLogicState;
  countdownState: NarrationCountdownState;

  messageAction: NarrationMessageAction;
};
