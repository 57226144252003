// key - count of passed hours after page(analytics entry) was created

import { CalendarProvider } from './calendar';
import { UniqViewers, ViewerPageSession, ViewerTabSession } from './viewers';
import { Conversion } from './conversion';
import { AnalyticsTab } from './analytics-tab';

// value - number of actions
export type AnalyticRecord = Record<string, number>;

export type OldAnalytics = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  pageViewsCount: number;
  pageViews: AnalyticRecord;
  uniqueVisitors: AnalyticRecord;
  timeOnPage: AnalyticRecord;
  timeOnPageCount: AnalyticRecord;
  averageTimeOnPage: AnalyticRecord;

  alertBarConversions: AnalyticRecord;
  alertBarTimeToConvert: AnalyticRecord;
  alertBarAverageTimeToConvert: AnalyticRecord;

  popupConversions: AnalyticRecord;
  popupTimeToConvert: AnalyticRecord;
  popupAverageTimeToConvert: AnalyticRecord;

  ctaConversions: AnalyticRecord;
  ctaTimeToConvert: AnalyticRecord;
  ctaAverageTimeToConvert: AnalyticRecord;

  gatedContentConversions: AnalyticRecord;
  gatedContentTimeToConvert: AnalyticRecord;
  gatedContentAverageTimeToConvert: AnalyticRecord;

  squeezePageConversions: AnalyticRecord;
  squeezePageTimeToConvert: AnalyticRecord;
  squeezePageAverageTimeToConvert: AnalyticRecord;

  requiredEmailConversions: AnalyticRecord;
  requiredEmailTimeToConvert: AnalyticRecord;
  requiredEmailAverageTimeToConvert: AnalyticRecord;

  tabs?: AnalyticsTab[];
};

export type Analytics = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  pageViews: ViewerPageSession[];
  uniqueVisitors: UniqViewers[];
  conversions: Conversion[];
  tabViews: ViewerTabSession[];
};

export type PingEvent = {
  sessionId: number;
  timestamp: Date;
};

export enum AnalyticEventName {
  PAGE_OPENED = 'Page opened',
  PAGE_CLOSED = 'Page closed',
  PAGE_RESUMED = 'Page resumed',
  POPUP_OPENED = 'Popup opened',
  POPUP_CLOSED = 'Popup closed',
  SUCCESS_CONVERSION = 'Success conversion',
  CONTENT_ITEM_OPENED = 'Content item opened',
}

type AnalyticEventBase<N extends AnalyticEventName, D = undefined> = {
  id: number;
  pageId: string;
  browserId: number;
  sessionId: number; // unique for each session/tab
  createdAt: Date;
  name: N;
  data?: D;
  isWrong: boolean;
  isNeedReprocess: boolean;
  contentItemId: number;
};

type PageOpenEvent = AnalyticEventBase<
  AnalyticEventName.PAGE_OPENED,
  {
    country: string;
    city: string;
    device: string;
    os: string;
    browser: string;
  }
>;

type PopupOpenedEvent = AnalyticEventBase<AnalyticEventName.POPUP_OPENED>;

type PopupClosedEvent = AnalyticEventBase<AnalyticEventName.POPUP_CLOSED>;

export enum ConversionSource {
  POPUP = 'Popup',
  CTA = 'CTA Section',
  ALERT_BAR = 'Alert Bar',
  GATED_CONTENT = 'Gated content',
  SQUEEZE_PAGE = 'Squeeze Page',
  REQUIRE_EMAIL_TO_VIEW = 'Required email',
}

export const CONVERSION_SOURCE_NAME = {
  [ConversionSource.REQUIRE_EMAIL_TO_VIEW]: 'Required email',
  [ConversionSource.ALERT_BAR]: 'Alert Bar',
  [ConversionSource.POPUP]: 'Pop-up',
  [ConversionSource.CTA]: 'CTA Section',
  [ConversionSource.GATED_CONTENT]: 'Gated Content',
  [ConversionSource.SQUEEZE_PAGE]: 'Squeeze Page',
} as const;

export type ConversionEventData = {
  source: ConversionSource;
  email?: string;
  name?: string;
  phone?: string;
  message?: string;
  calendarProvider?: CalendarProvider;
  isEmailVerified?: boolean;
};

export type SuccessConversionEvent = AnalyticEventBase<
  AnalyticEventName.SUCCESS_CONVERSION,
  ConversionEventData
>;

type PageClosedEvent = AnalyticEventBase<AnalyticEventName.PAGE_CLOSED>;

export type AnalyticsEventType =
  | PageOpenEvent
  | PageClosedEvent
  | PopupClosedEvent
  | PopupOpenedEvent
  | SuccessConversionEvent;

export type AnalyticsEventData = Pick<AnalyticsEventType, 'name' | 'data'>;

export type PageOverview = {
  id: string;
  views: number;
  uniqViews: number;
  conversions: number;
  lastViewed: Date;
  published: boolean;
  title: string;
  icon?: string;
  logo?: string;
  pageSlug: string;
  sequenceNumber: string;
  users: {
    id: number;
    role: string;
    email: string;
    name: string;
    photoUrl?: string;
    userId: string;
  }[];
};

export type PageOverviewTotal = {
  totalPages: number;
  totalPageViews: number;
  totalLeads: number;
};
