import { FC, PropsWithChildren } from 'react';
import { PlayerBubbleContext } from './PlayerBubbleContext';

type Props = {
  onEditClick?: () => void;
  interactive?: boolean;
};

export const PlayerBubbleProvider: FC<PropsWithChildren<Props>> = ({
  children,
  interactive,
  onEditClick,
}) => (
  <PlayerBubbleContext.Provider value={{ interactive, onEditClick }}>
    {children}
  </PlayerBubbleContext.Provider>
);
