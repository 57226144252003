import { FC, useRef } from 'react';
import ReactPlayer from 'react-player';

import { usePlayer, usePlayerActions, useRetry } from '../../hooks';

import { PlayerControls } from './PlayerControls';
import { PlayerOverlay } from './PlayerOverlay';
import { PlayerHotKeys } from './PlayerHotKeys';

export const Player: FC = () => {
  const playerRef = useRef<ReactPlayer>(null);

  const { state, config } = usePlayer();

  const {
    handlePlay,
    handlePause,
    handleProgress,
    handleDuration,
    handleEnded,
  } = usePlayerActions();

  const { handleError } = useRetry(playerRef);

  const { url, aspectRatio, hotKeys } = config;
  const { playing, playbackRate, volume, fullscreen } = state;

  return (
    <>
      <ReactPlayer
        className="relative bg-base-black"
        ref={playerRef}
        url={url}
        width="100%"
        height="100%"
        playbackRate={playbackRate}
        playing={playing}
        volume={volume}
        onError={handleError}
        onPause={handlePause}
        onPlay={handlePlay}
        onDuration={handleDuration}
        onProgress={handleProgress}
        onEnded={() => handleEnded(playerRef)}
        config={{
          file: {
            attributes: {
              style: { aspectRatio },
              className: 'w-full bg-base-black',
              controlsList: 'nodownload',
            },
          },
        }}
      />
      <PlayerOverlay playerRef={playerRef} />
      <PlayerControls playerRef={playerRef} />
      {(hotKeys || fullscreen) && <PlayerHotKeys />}
    </>
  );
};
