import { RootState } from '../../../app';
import { noAudio, noVideo } from '../config';
import { NarrationMessage } from './message-action';
import {
  DevicePermission,
  NarrationCountdownState,
  NarrationLogicState,
} from './types';

export const selectNarration = ({ narration }: RootState) => narration;
export const selectNarrationData = ({ narration }: RootState) =>
  narration.narration;

export const selectPopupOpen = ({ narration }: RootState) =>
  narration.popupOpen;

export const selectNarrationMessageAction = ({ narration }: RootState) =>
  narration.messageAction;

export const checkIsNoAudio = ({ narration }: RootState) =>
  narration.audioId === noAudio.id;

export const checkIsNoVideo = ({ narration }: RootState) =>
  narration.videoId === noVideo.id;

export const checkIsVideoPermissionInActive = ({ narration }: RootState) =>
  [DevicePermission.DENIED, DevicePermission.PROMPT].includes(
    narration.videoPermission
  );

export const checkIsNarrationRecordingSessionActive = ({
  narration,
}: RootState) =>
  [
    NarrationLogicState.Initialization,
    NarrationLogicState.LoadSession,
    NarrationLogicState.Running,
    NarrationLogicState.Paused,
    NarrationLogicState.Resuming,
  ].includes(narration.logicState);

export const checkIsNarrationRecordingSessionStopped = ({
  narration,
}: RootState) => [NarrationLogicState.Stopped].includes(narration.logicState);

export const checkIsNarrationRecorderActive = ({ narration }: RootState) =>
  [
    NarrationLogicState.Running,
    NarrationLogicState.Paused,
    NarrationLogicState.Resuming,
  ].includes(narration.logicState);

export const checkIsNarrationCountdownActive = ({ narration }: RootState) =>
  NarrationCountdownState.Active === narration.countdownState;

export const checkIsNarrationMachineIdle = ({ narration }: RootState) =>
  narration.messageAction.type === NarrationMessage.OnIdle;

export const checkIsPopupVisible = ({ narration }: RootState) => {
  const { popupOpen, logicState, countdownState } = narration;

  const isNarrationCountdownIdle =
    countdownState === NarrationCountdownState.Idle;

  const isNarrationRecordingSessionStopped =
    logicState === NarrationLogicState.Stopped;

  return (
    popupOpen &&
    (isNarrationRecordingSessionStopped || isNarrationCountdownIdle)
  );
};
