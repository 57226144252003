import { FC, useCallback, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';

import { useWindowWidth } from '@distribute/frontend/utils';

import cn from 'classnames';

import * as Portal from '@radix-ui/react-portal';

import { useDispatch, useSelector } from 'react-redux';

import { NarrationToolbar } from '../narration-toolbar';
import { NarrationPopup } from '../narration-popup';
import { NarrationRecorder } from '../narration-recorder';
import { NarrationPermission } from '../narration-permission';

import { narrationModel } from '../../model';

import { NarrationCountdown } from '../narration-countdown';
import { NarrationCountdownState } from '../../model/types';

const NARRATION_POSITION = 'narration-position';

export const Narration: FC = () => {
  const firstRender = useRef<boolean>(true);
  const draggableRef = useRef<Draggable>(null);

  const dispatch = useDispatch();

  const { windowHeight, windowWidth } = useWindowWidth();

  const [position, setPosition] = useState(() => {
    const [x, y] = localStorage.getItem(NARRATION_POSITION)?.split('_') || [];

    return x && y ? { x: +x, y: +y } : null;
  });
  const [isDragging, setIsDragging] = useState(false);

  const { countdownState } = useSelector(
    narrationModel.selectors.selectNarration
  );

  const isNarrationRecordingSessionActive = useSelector(
    narrationModel.selectors.checkIsNarrationRecordingSessionActive
  );

  const isNarrationCountdownActive = useSelector(
    narrationModel.selectors.checkIsNarrationCountdownActive
  );

  const isPopupVisible = useSelector(
    narrationModel.selectors.checkIsPopupVisible
  );

  const isNarrationCountdownIdle =
    countdownState === NarrationCountdownState.Idle;

  const handleSetPosition = useCallback((x: number, y: number) => {
    setPosition({ x, y });
    localStorage.setItem(NARRATION_POSITION, `${x}_${y}`);
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    handleSetPosition(0, 0);
    draggableRef.current?.setState({ x: 0, y: 0 });
  }, [windowWidth, windowHeight, handleSetPosition]);

  useEffect(() => {
    return () => {
      dispatch(narrationModel.actions.resetAll());
    };
  }, [dispatch]);

  return (
    <Portal.Root asChild>
      <div
        className={cn('fixed inset-0 z-100', {
          'pointer-events-none': !isDragging,
          'select-none': isDragging,
        })}
      >
        <div className="absolute inset-0 !top-16">
          {isPopupVisible && (
            <Draggable
              ref={draggableRef}
              defaultPosition={position ?? undefined}
              onStart={() => setIsDragging(true)}
              onStop={(_, { x, y }) => {
                setIsDragging(false);
                handleSetPosition(x, y);
              }}
              bounds="parent"
              axis="both"
              handle=".narration-draggable-item"
              grid={[1, 1]}
              scale={1}
            >
              <div className="absolute pointer-events-auto left-70 bottom-8">
                <NarrationPopup />
                <NarrationPermission />
              </div>
            </Draggable>
          )}
          <NarrationRecorder />
          {isNarrationRecordingSessionActive && !isNarrationCountdownIdle && (
            <NarrationToolbar />
          )}
        </div>
        {isNarrationCountdownActive && <NarrationCountdown />}
      </div>
    </Portal.Root>
  );
};
