import { FC } from 'react';
import cn from 'classnames';

import { Icon, Icons } from '../../icons';
import { usePlayer, usePlayerActions, usePlayerBubble } from '../../hooks';

export const PlayerBubblePreview: FC = () => {
  const { onEditClick } = usePlayerBubble();

  const { config, state } = usePlayer();
  const { handleStart } = usePlayerActions();

  const { start } = state;
  const { posterUrl } = config;

  if (start) return null;

  return (
    <div
      className={cn(
        'absolute inset-0 flex bg-base-black h-full cursor-pointer'
      )}
    >
      <div
        onClick={handleStart}
        className={cn(
          'absolute inset-0 grid place-items-center z-[1]',
          'group-hover:bg-base-black/50'
        )}
      >
        <button
          type="button"
          className={cn(
            'rounded-full bg-primary-500 hover:bg-primary-600 grid place-items-center',
            'invisible group-hover:visible w-10 h-10'
          )}
        >
          <Icon svg={Icons.PlayPreview} size="sm" className="text-base-white" />
        </button>
      </div>
      {onEditClick && (
        <div className="absolute top-2 right-2 z-[1]">
          <button
            onClick={onEditClick}
            type="button"
            className={cn(
              'rounded-lg border border-gray-200 bg-base-white/80 grid place-items-center',
              'w-10 h-10'
            )}
          >
            <Icon svg={Icons.Edit} size="xs" className="text-gray-700" />
          </button>
        </div>
      )}

      <img
        src={posterUrl}
        alt="poster"
        className="block w-full h-full object-contain scale-x-[-1]"
      />
    </div>
  );
};
